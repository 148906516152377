const _isDev = window.location.port.indexOf('4200') > -1 || window.location.port.indexOf('3003') > -1;
 var dataUrl = _isDev ? 'http://localhost:3000/api' : window.location.protocol+'//'+window.location.host+'/api'; 
 var elkUrl = "rd.t2s.cloud"
 var elkUsr="elk_usr";
 var apiKey="key"
 var elkPwd="pwd"
 if (!_isDev) { console.log=function() {}}
//window.location.protocol+'//'+window.location.hostname; //'https://rd.t2s.cloud/middleware-idru';
export var ELK = {
    ELK_URL: elkUrl,
    ELK_USR: elkUsr,
    ELK_PWD: elkPwd,
    ELK_API_KEY: apiKey
}
export var ENV = {
    API_URL: dataUrl,
    API_ANAF:dataUrl+'/organization/anaf',
    API_POST_ORGANIZATION:dataUrl+'/organization/create',
    API_POST_USER_ORGANIZATIONS:dataUrl+'/user/organizations',
    API_POST_ORGANIZATION_DETAILS:dataUrl+'/organization/details',
    API_POST_USER_ORGANIZATIONS_DETAILS:dataUrl+'/user/organizations/details',
    API_POST_SIGN_UP: dataUrl + "/user/signup ",
    API_POST_SIGN_IN: dataUrl + "/user/signin ",
    API_POST_SIGN_OUT: dataUrl + "/user/signout ",
    API_POST_MFA_SIGN_IN: dataUrl + "/user/mfasignin ",
    API_POST_ACCESS: dataUrl + "/authorize/access",
    API_POST_UPDATE_USER: dataUrl + "/user/update",
    API_POST_USER_DETAILS: dataUrl + "/user/details",
    API_POST_INVOICE:dataUrl + "/invoice/account",
    API_POST_VALIDATE_TOKEN: dataUrl+"/admin/verify_token",
    API_POST_VERIFY_ACCOUNT: dataUrl+"/account/verify",
    API_POST_USER_ACCOUNTS: dataUrl+"/account/user",
    API_POST_USER_PROFILE: dataUrl+"/user/profile",
    API_POST_INVOICE_CONTRACT: dataUrl+"/invoice/account",
    API_POST_INVOICE_DETAILS : dataUrl + "/invoice/details",
    API_POST_CHANGE_EMAIL: dataUrl + "/user/email",
    API_POST_CHANGE_PASSWORD: dataUrl+"/user/password",
    API_POST_RESET_PASSWORD: dataUrl+"/user/reset",
    API_POST_CHECK_FOR_USER_ACCOUNT: dataUrl+'/account/search',
    API_POST_PAYMENT:dataUrl+'/authorize/order',
    API_POST_TRANSACTION:dataUrl+"/account/transaction",
    API_POST_TRANSACTIONS:dataUrl+"/account/transactions",
    API_POST_PAYMENTS:dataUrl+"/account/payments",
    API_POST_SOLD:dataUrl+"/account/sold",
    API_GET_ORGANIZATION_PERSONS:dataUrl+'/organization/persons',
    API_GET_ORGANIZATION_USERS:dataUrl+'/organization/users',
    API_POST_CREATE_UPDATE_PERSON:dataUrl+"/person/update",
    API_UPDATE_ALLOC_PERSONS: dataUrl+"/person/update_alloc_persons",
    API_GET_PERSON_DETAILS:dataUrl+"/person/details",
    API_DELETE_PERSON:dataUrl+"/person/delete",
    API_GET_SUBORGANIZATIONS_NAME_BY_ID:dataUrl+"/organization/suborganization_name_by_id",
    API_POST_PARENT_AND_SUBORGANIZATIONS:dataUrl+"/organization/org_and_suborgs",
    API_GET_ADMIN_ALL_ORGS:dataUrl+"/admin/parent_organizations",
    API_GET_ADMIN_ORGS:dataUrl+"/admin/get_organizations",
    API_POST_UPDATE_ORGANIZATION:dataUrl+"/organization/update",
    API_POST_UPDATE_ORGANIZATION_AND_PERSON_MONITORING_PRICE:dataUrl+"/organization/update_organization_and_monitoring_price",
    API_GET_SUBORGANIZATIONS:dataUrl+'/organization/children',
    API_GET_PERSON_BY_NIN:dataUrl+'/person/get_person_by_nin',
    API_GET_PERSON_BY_CODE: dataUrl+ '/person/get_person_by_code',
    API_GET_SUMMARY_PERSON_FROM_BI: dataUrl+'/bi/bi_date',
    API_UPDATE_SUBORGANIZATION:dataUrl+'/organization/children/update',
    API_UPDATE_ALLOCATION:dataUrl+'/allocation/update_allocation',
    API_UPDATE_RECORDS:dataUrl +'/allocation/update_alloc_org_details',
    API_DELETE_SUBORGANIZATION:dataUrl+'/organization/children/delete',
    API_DELETE_ORGANIZATION:dataUrl+'/organization/delete',
    API_GET_USER_ROLES:dataUrl+'/app/roles',
    API_GET_USER_NON_SYSTEM_ROLES:dataUrl+'/app/non_system_roles',
    API_POST_CREATE_USER: dataUrl + "/user/create",
    API_POST_DELETE_USER: dataUrl + "/user/delete",
    API_POST_DELETE_NOTIFICATION: dataUrl + "/admin/delete_notification",
    API_GET_PARENT_ORGANIZATIONS: dataUrl + "/organization/parents",
    API_ADMIN_GET_PARENT_ORGANIZATIONS: dataUrl + "/admin/parents",
    API_ADMIN_GET_PERSONS: dataUrl + "/admin/persons",
    API_ADMIN_GET_PERSONS_FROM_ALL_ORGANIZATIONS: dataUrl + "/admin/persons_from_all_organizations",
    API_ADMIN_GET_USERS: dataUrl + "/admin/users",
    API_ADMIN_GET_SUBORGS_USERS: dataUrl + "/admin/suborganizations_users",
    API_ADMIN_GET_SYSTEM_USERS: dataUrl + "/admin/system_users",
    API_ADMIN_POST_GENERIC: dataUrl + "/admin/generic/post",
    API_ADMIN_GET_GENERIC: dataUrl + "/admin/generic/get",
    API_ADMIN_GET_GENERIC_PERSON: dataUrl + "/admin/generic/person",
    API_ADMIN_UPDATE_GENERIC: dataUrl + "/admin/generic/update",
    API_ADMIN_UPDATE_BULK_ADD: dataUrl + "/admin/update_bulk",
    API_ADMIN_UPDATE_GENERIC_MANY: dataUrl + "/admin/generic/update_many",
    API_ADMIN_UPDATE_GENERIC_MANY_QUERY: dataUrl + "/admin/generic/update_query",
    API_ADMIN_UPDATE_GENERIC_MULTIPLE_COLLECTIONS: dataUrl + "/admin/generic/update_query_multiple_collections",
    API_ADMIN_UPDATE_VALID_DOSE: dataUrl + "/admin/generic/update_valid_dose",
    API_ADMIN_UPDATE_GENERIC_RETURNED_DOSIMETERS: dataUrl + "/admin/generic/update_allocated_dosimeters",
    API_ADMIN_UPDATE_GENERIC_ANNEALED_DOSIMETERS: dataUrl + "/admin/generic/update_annealed_dosimeters",
    API_ADMIN_DELETE_GENERIC: dataUrl + "/admin/generic/delete",
    API_ADMIN_UPLOAD_FILE: dataUrl + "/admin/processXLSXFile",
    API_ADMIN_CHECK_UPLOADED_LIST: dataUrl + "/admin/checklist",
    API_ADMIN_VALIDATE_ASSIGMENT: dataUrl + "/admin/validate_assigment",
    API_ADMIN_FIND_ASSIGNED_DOSIMETER: dataUrl + "/admin/find_assigned_dosimeter",
    API_GET_ALLOCABLE_WEARERS: dataUrl + "/allocation/wearers",
    API_GET_EDITABLE_WEARERS:dataUrl + "/allocation/editable_wearers",
    API_COUNT_STUFFS: dataUrl + "/admin/count_assets",
    API_ADMIN_ASIGN_DOSIMETER_TO_WEARER: dataUrl + "/allocation/assign",
    API_ADMIN_GET_BATCHES: dataUrl + "/allocation/batches",
    API_ADMIN_GET_DOSES_BATCHES: dataUrl + "/allocation/select_dose_records",
    API_ADMIN_GET_BATCH_DETAILS: dataUrl + "/allocation/batch_detail",
    API_ADMIN_GET_BATCH_REPORT_DETAILS: dataUrl + "/allocation/report_batch_detail",
    API_ADMIN_UPDATE_DOSE_RECORD: dataUrl + "/allocation/update_dose_record",
    API_ADMIN_UPDATE_PERSON_MONITORING_TYPE: dataUrl + "/allocation/update_person_monitoring_type",
    API_ADMIN_ADD_EXTRA_DOSE_RECORDS: dataUrl + "/allocation/extra_dose_records",
    API_ADMIN_UPDATE_ALLOCATION_QUERY: dataUrl + "/allocation/update_query",
    API_ADMIN_GET_PRINTERS: dataUrl+"/print/get_printers",
    API_ADMIN_GET_PRINT_LABELS: dataUrl+"/print/labels",
    API_ADMIN_POST_PRINT_LABEL: dataUrl+"/print/print_label",
    API_ADMIN_POST_DELETE_LABEL: dataUrl+"/print/delete_label",
    API_ADMIN_GET_UNWIND_DOSIMETERS: dataUrl+"/allocation/unwind_dosimeters",
    API_ADMIN_DELETE_ALLOCATION: dataUrl+"/allocation/delete_allocation",
    API_INVALIDATE_BACTH: dataUrl+"/allocation/invalidate_batch",
    API_ADMIN_DELETE_FREE_DOSIMETER:dataUrl+"/admin/detele_free_dosimeter",
    API_ADMIN_GET_DOSIMETERS:dataUrl+"/admin/get_dosimeters",
    API_ADMIN_SWAP_DOSIMETERS: dataUrl + "/allocation/swap_dosimeters", 
    API_ADMIN_SWAP_PERSON_ID: dataUrl + "/allocation/swap_person",
    API_ADMIN_SWAP_DOSIMETERS_IDS: dataUrl + "/allocation/swap_dosimeters_ids",
    API_GET_ENDPOINTS: dataUrl + "/app/endpoints",
    API_GET_APPCONFIG: dataUrl + "/app/app_config",
    API_GET_COLLECTIONS: dataUrl + "/admin/db_collections",
    API_ADMIN_UPDATE_DOSI_DATA: dataUrl + "/allocation/update_dosi_data",
    API_ADMIN_SEARCH_DOSIMETERS: dataUrl + "/admin/search_dosimeters",
    API_ADMIN_UPDATE_DOSIMETER_STATES: dataUrl + "/admin/dosimeter/states",
    API_ADMIN_POST_LOOKUPUSER: dataUrl + "/admin/users/lookup",
    API_ADMIN_SEQUENCIAL_WEARER_CODE: dataUrl + "/admin/sequencial_wearer_code",
    API_ALLOCATION_REGISTER_DOSE_REPORT: dataUrl + "/allocation/register_dose_report",
    API_ADMIN_COUNT_COLLECTION: dataUrl + "/admin/count_collection",
    API_ADMIN_COUNT_ACTIVE_WEARERS: dataUrl + "/admin/count_active_wearers",
    API_ADMIN_DELETE_DATA: dataUrl + "/admin/delete_data",
    API_ADMIN_POST_REPORT_NOTIFICATION: dataUrl + "/organization/report_notifications",
    API_ADMIN_POST_SHIPPED_DOSIMETERS_NOTIFICATION: dataUrl + "/organization/shipped_dosimeters_notifications",
    API_ALFRESCO_GENERATE_PDF: dataUrl + "/alfresco/generatePDF?noderef=",
    API_ALFRESCO_BROWSE_REPORT_FOLDER: dataUrl + '/alfresco/browseReportFolders',
    API_ALFRESCO_BROWSE_SITE_FOLDER: dataUrl + '/alfresco/alf_browseFolder',
    API_ALFRESCO_CONCATENATE_FILES: dataUrl + '/alfresco/concatenateFiles',
    API_ALFRESCO_QUICK_GENERATOR: dataUrl + '/alfresco/quickGenerator',
    API_ALFRESCO_GET_TEMPLATES: dataUrl + '/alfresco/getTemplates',
    API_ALFRESCO_GET_FILE: dataUrl + '/alfresco/getFile',
    API_AUDIT_LOGS:dataUrl + '/admin/auditLogsHistory',
    API_GET_DOSE_RECORDS:dataUrl + "/allocation/get_dose_records",
    API_SEARCH_WEARERS:dataUrl + "/admin/search_wearers",
    API_VERIFY_PASSWORD:dataUrl + "/user/verify_pass"

}