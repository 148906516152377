import { Injectable } from '@angular/core';
import {ENV, ELK} from './data.conf';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastService } from '../toast/toast.service';
import { Observable, Subject } from 'rxjs';
import { stringify } from 'querystring';
import { EncryptService } from '../encrypt/encrypt.service';
import { AlfrescoService } from '../alfresco/alfresco.service';
import { ENV as ALF_ENV } from '../alfresco/alfresco.conf';
import { count } from 'console';
import { OrganizationModel } from '../../models/organization.model';
import { LanguageService } from '../language/language.service';
import { AuthService } from '../auth/auth.service';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  private heads: HttpHeaders;
  private invoiceID: String;
  public contracts:any;
  public id_contract;
  public maint:any;
  public ELK:any;
  public app_config:any=null;
  private objectdata = new Subject<any>();
  public license:any;
  //organizations:OrganizationModel=new OrganizationModel();


  constructor(private http: HttpClient, private router: Router,public toastService: ToastService, 
    private alfService:AlfrescoService,
    private ls: LanguageService,
    private encryptService:EncryptService) {
      this.ELK={};
     this.getMaintenance();
    console.log("DataService", this.router);
    this.heads = new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin);
   }

   setObjectData(msg:any){
    this.objectdata.next(msg);
  }
  
  getObjectData(): Observable<any>{
    return this.objectdata.asObservable();
  }

  public setInvoiceId(_id) {
    this.invoiceID = _id;
  }
  public getInvoiceId(): String {
    return this.invoiceID;
  }
  private async getAppConfig():Promise<any> {
    let r=await this.http.get(ENV.API_GET_APPCONFIG,
      {headers:this.heads, responseType: "text"})
    .toPromise()
    .then(response=>response)
    .catch(
      err => {
        console.log("app config error",err)
        this._handleError(this, err);
      }
      );
      try {
      //console.log("----------APP config r is ", r) 
      let resp=JSON.parse(this.encryptService.decodeString(r));
      this.app_config=resp.config_obj;
      for (var k in resp.app_config) {
        this.app_config[k]=resp.app_config[k];
      }
      this.ls.languages=resp.installed_languages;
      this.license = resp.app_license;
      AuthService.instance.setLicenseObject(this.license);
      console.log("++++++ ++++app config response is ", resp);
      } catch(e) { console.log("MISSING APP CONFIG")}
  }
  public async validateToken(token):Promise<any> {
    return this.http.post(ENV.API_POST_VALIDATE_TOKEN , {},
      {
        headers:
        new HttpHeaders()
      .set('Access-Control-Allow-Headers', window.location.origin)
      .set('Access-Control-Allow-Origin', window.location.origin)
      .set('authorization', token)})
      .toPromise()
      .then(response => JSON.parse( JSON.stringify(response)))
      .catch(
       err => {
         this._handleError(this, err);
       }
       );
  }
  private async getServerConfig():Promise<any> {
  
    let r=await this.http.get(ENV.API_GET_ENDPOINTS,
      {headers:this.heads, responseType: "text"})
    .toPromise()
    .then(response=>response)
    .catch(
      err => {
        console.log("config error",err)
        this._handleError(this, err);
      }
      );
      console.log("----------config r is ", r)
      let resp=JSON.parse(this.encryptService.decodeString(r));
      this.alfService.setDataService(this);
      this.alfService.setConfig(resp[0]);
      for (var k in resp[1]) {
        this.ELK[k]=resp[1][k];
      }
      console.log("++++++++++config response is ", resp);
  }
  public async postUserRegistration(data: any): Promise<any> {
    console.log(data);
     return this.http.post(ENV.API_POST_CREATE_USER , data,
      {
        headers: this.heads,

      })
       .toPromise()
       .then(response => JSON.parse( JSON.stringify(response)))
       .catch(
        err => {
          this._handleError(this, err);
        }
        );
   }
   public async signout(token):Promise<any> {
      return this.http.post(ENV.API_POST_SIGN_OUT, null,
          { headers:
            new HttpHeaders()
        .set('Access-Control-Allow-Headers', window.location.origin)
        .set('Access-Control-Allow-Origin', window.location.origin)
        .set('authorization', token)
          })
      .toPromise()
      .then(response=> JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
      )
   }
   public async postSignIn(data: any): Promise<any> {
     console.log(data);
          return this.http.post(ENV.API_POST_SIGN_IN , data,
       {
         headers: this.heads,

       })
       .toPromise()
       .then(response => JSON.parse( JSON.stringify(response)))
       .catch(
        err => {
          this._handleError(this, err);
        }
        );
   }

   public async postMFASignIn(data: any): Promise<any> {
    console.log(data);
         return this.http.post(ENV.API_POST_MFA_SIGN_IN , data,
      {
        headers: this.heads,

      })
      .toPromise()
      .then(response => JSON.parse( JSON.stringify(response)))
      .catch(
       err => {
         this._handleError(this, err);
       }
       );
  }



   public async postUserUpdate(object, token): Promise<any> {
     let postData;
    postData = {
    
      object

    };
    console.log(postData);

       return this.http.post(ENV.API_POST_UPDATE_USER , postData,
       {
        headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)})
       .toPromise()
       .then(response => JSON.parse( JSON.stringify(response)))
       .catch(
        err => {
          this._handleError(this, err);
        }
        );
   }

   public async getInvoiceOverview(id, token): Promise<any> {
     const _q = { 'factura' : id};
     return this.http.post(ENV.API_POST_INVOICE_DETAILS, _q,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
    .toPromise()
    .then(response => JSON.parse( JSON.stringify(response)))
    .catch(
      err => {
        this._handleError(this, err);
      }
      );
   }
   

   public async getAccountInvoices(data, token): Promise<any> {
     // { "query": { "id_contract": 236402 }, "limit": 3, "skip":0 }
     if (!data.limit) { data.limit = 12; }
     if (!data.skip) { data.skip = 0; }
     console.log('data is ', data);
    return this.http.post(ENV.API_POST_INVOICE , data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
    .toPromise()
    .then(response => JSON.parse( JSON.stringify(response)))
    .catch(
      err => {
        this._handleError(this, err);
      }
      );
}

  public async getUserAcounts(id: string, token: string): Promise<any> {
    const data = { _id: id};
    return this.http.post(ENV.API_POST_USER_ACCOUNTS, data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }

  public async resetPassword(user: any):Promise<any> {
    let username=user.username;
    let email=user.email;
    const data = { "username": username, "email": email }
    return this.http.post(ENV.API_POST_RESET_PASSWORD, data)
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)))
    .catch(
      err => {
        this._handleError(this, err);
      }
    )
  }
  
  public async changePassword(userid, _old: any, _new: any, token: string): Promise<any> {
     //_old = window.btoa(_old);
   //_new = window.btoa(_new);
    const data = { username: userid, 'oldpassword': _old, 'password': _new};
    console.log(data);
    return this.http.post(ENV.API_POST_CHANGE_PASSWORD, data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }
 
  public async getTransaction(id,token): Promise<any> {
    //_old = window.btoa(_old);
  //_new = window.btoa(_new);
   const data = { 'id': id};
   console.log(data);
   return this.http.post(ENV.API_POST_TRANSACTION, data,
     { headers:
       new HttpHeaders()
   .set('Access-Control-Allow-Headers', window.location.origin)
   .set('Access-Control-Allow-Origin', window.location.origin)
   .set('authorization', token)
     })
     .toPromise()
     .then(response => JSON.parse(JSON.stringify(response)))
     .catch(
       err => {
         this._handleError(this, err);
       }
       );
 }
 public async getTransactions(abonat,token): Promise<any> {
  //_old = window.btoa(_old);
//_new = window.btoa(_new);
 const data = { 'abonat': abonat};
 console.log(data);
 return this.http.post(ENV.API_POST_TRANSACTIONS, data,
   { headers:
     new HttpHeaders()
 .set('Access-Control-Allow-Headers', window.location.origin)
 .set('Access-Control-Allow-Origin', window.location.origin)
 .set('authorization', token)
   })
   .toPromise()
   .then(response => JSON.parse(JSON.stringify(response)))
   .catch(
     err => {
       this._handleError(this, err);
     }
     );
}

public async getPayments(abonat,token): Promise<any> {
  
 const data = { "query" : { "abonat": abonat }, "limit": 0, "skip":0}
 console.log(data);
 return this.http.post(ENV.API_POST_PAYMENTS, data,
   { headers:
     new HttpHeaders()
 .set('Access-Control-Allow-Headers', window.location.origin)
 .set('Access-Control-Allow-Origin', window.location.origin)
 .set('authorization', token)
   })
   .toPromise()
   .then(response => JSON.parse(JSON.stringify(response)))
   .catch(
     err => {
       this._handleError(this, err);
     }
     );
}

public async getSold(abonat,token): Promise<any> {
  
  const data = { "query" : { "_id": String(abonat) }, "limit": 1, "skip":0}
 
 
 
  
  console.log(data);
  return this.http.post(ENV.API_POST_SOLD, data,
    { headers:
      new HttpHeaders()
  .set('Access-Control-Allow-Headers', window.location.origin)
  .set('Access-Control-Allow-Origin', window.location.origin)
  .set('authorization', token)
    })
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)))
    .catch(
      err => {
        this._handleError(this, err);
      }
      );
 }
  
  public async getUserProfile(token: string): Promise<any> {
    const data = {};
    return this.http.post(ENV.API_POST_USER_PROFILE, data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }

  




      async getMaintenance(){
        await this.getAppConfig();
        await this.getServerConfig();
      }
  showError() {
    this.toastService.show('Sesiunea a expirat', {
      classname: 'btn-danger btn d-block',
      delay: 8000 ,
      autohide: true,
      headertext: 'Error!!!'
    });
  }

   private _handleError(who: any, err: HttpErrorResponse | any) {
     if (err.status == 403) {
      who.showError();
      who.router.navigateByUrl('login');
     }
     //this.router.navigateByUrl('login');
    // this.showError();
    const errorMsg = err.message || 'Error: Unable to complete request.';
    if (err.message && err.message.indexOf('No JWT present') > -1) {
    }

    return errorMsg;
  }

  setContract(id_contract){
    
this.id_contract=id_contract;
  }
  getContract(){
    
    return this.id_contract || 0;
  }


/* ||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
|||||||||||||||||||||||||||||||||| DOSITRACKER ||||||||||||||||||||||||||||||||||||||||||||
|||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||| */
public async getOrganizationAnaf(data): Promise<any> {
  return this.http.post(ENV.API_ANAF, data,
    { headers:
      new HttpHeaders()
  .set('Access-Control-Allow-Headers', window.location.origin)
  .set('Access-Control-Allow-Origin', window.location.origin)
 
    })
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)))
    .catch(
      err => {
        this._handleError(this, err);
      }
      );
}

public async postAccessLink(id: any): Promise<any> {
         return this.http.get(ENV.API_POST_ACCESS +'/'+id,
     )
      .toPromise()
      .then(response => JSON.parse( JSON.stringify(response)))
      .catch(
       err => {
         this._handleError(this, err);
       }
       );
  }


public async registerOrganization(data): Promise<any> {
  console.log(" registering ", ENV.API_POST_ORGANIZATION, data)

  return this.http.post(ENV.API_POST_ORGANIZATION, data,
    { headers:
      new HttpHeaders()
  .set('Access-Control-Allow-Headers', window.location.origin)
  .set('Access-Control-Allow-Origin', window.location.origin)
 
    })
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)))
    .catch(
      err => {
        this._handleError(this, err);
      }
      );
}

public async getUserOrganizations(preffered_organization:string,token:string): Promise<any> {
  let data = {
    preffered_organization
  };

  return this.http.post(ENV.API_POST_USER_ORGANIZATIONS, data,
    { headers:
      new HttpHeaders()
  .set('Access-Control-Allow-Headers', window.location.origin)
  .set('Access-Control-Allow-Origin', window.location.origin)
  .set('authorization', token)
    })
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)))
    .catch(
      err => {
        this._handleError(this, err);
      }
      );
}

public async getUserOrganizationsDetails(token: string): Promise<any> {
  let data = {
    
  };

  return this.http.post(ENV.API_POST_USER_ORGANIZATIONS_DETAILS, data,
    { headers:
      new HttpHeaders()
  .set('Access-Control-Allow-Headers', window.location.origin)
  .set('Access-Control-Allow-Origin', window.location.origin)
  .set('authorization', token)
    })
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)))
    .catch(
      err => {
        this._handleError(this, err);
      }
      );
}
public async countCollection(_query, token: string): Promise<any>
{
  return this.http.post(ENV.API_ADMIN_COUNT_COLLECTION, _query, 
    { headers:
      new HttpHeaders()
  .set('Access-Control-Allow-Headers', window.location.origin)
  .set('Access-Control-Allow-Origin', window.location.origin)
  .set('authorization', token)
    })
  .toPromise()
  .then(response=>JSON.parse(JSON.stringify(response)))
  .catch(
    err=> {
      this._handleError(this, err)
    }
  )
};

public async countActiveWearers(_query, token: string): Promise<any>
{
  return this.http.post(ENV.API_ADMIN_COUNT_ACTIVE_WEARERS, _query, 
    { headers:
      new HttpHeaders()
  .set('Access-Control-Allow-Headers', window.location.origin)
  .set('Access-Control-Allow-Origin', window.location.origin)
  .set('authorization', token)
    })
  .toPromise()
  .then(response=>JSON.parse(JSON.stringify(response)))
  .catch(
    err=> {
      this._handleError(this, err)
    }
  )
}

public async getOrganizationDetails(preffered_organization:string, token: string): Promise<any> {
  let data = {
    preffered_organization
  };

  return this.http.post(ENV.API_POST_ORGANIZATION_DETAILS, data,
    { headers:
      new HttpHeaders()
  .set('Access-Control-Allow-Headers', window.location.origin)
  .set('Access-Control-Allow-Origin', window.location.origin)
  .set('authorization', token)
    })
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)))
    .catch(
      err => {
        this._handleError(this, err);
      }
      );
}

public async getUserDetails(token: string, userId?:any, active?:boolean, byusername?:boolean): Promise<any> {
  let data:any = {};
  console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx",active, userId, byusername)
  if(userId){
    console.log(userId, byusername)
    if (byusername==true) {
    data={ username: userId}
    } else {
    data = {
      userId: userId
    }
    }
    if (active==true) {
      data.active=true;
    } else if (active==false) {
      data.active=false;
    }
  }
  console.log("sent object is ------------", userId, "data is ", data, byusername, active)
  return this.http.post(ENV.API_POST_USER_DETAILS, data,
    { headers:
      new HttpHeaders()
  .set('Access-Control-Allow-Headers', window.location.origin)
  .set('Access-Control-Allow-Origin', window.location.origin)
  .set('authorization', token)
    })
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)))
    .catch(
      err => {
        this._handleError(this, err);
      }
      );
}

public async changeEmail(cui:any, _old: any, _new: any, token: string): Promise<any> {
  //_old = window.btoa(_old);
//_new = window.btoa(_new);
 const data = { username: cui,'oldemail': _old, 'newemail': _new};
 console.log(data);
 return this.http.post(ENV.API_POST_CHANGE_EMAIL, data,
   { headers:
     new HttpHeaders()
 .set('Access-Control-Allow-Headers', window.location.origin)
 .set('Access-Control-Allow-Origin', window.location.origin)
 .set('authorization', token)
   })
   .toPromise()
   .then(response => JSON.parse(JSON.stringify(response)))
   .catch(
     err => {
       this._handleError(this, err);
     }
     );
}


public async getOrganizatonPersons(organization:string,token: string, active?:boolean, nAlloc?:any): Promise<any> {
  let data:any = {organization:organization};
  if (active==true) {
    data.active=true;
  } else if (active==false) {
    data.active=false;
  }
  if (nAlloc!=undefined) {
    data.allocation=nAlloc;
  }
  return this.http.post(ENV.API_GET_ORGANIZATION_PERSONS,data,
    { headers:
      new HttpHeaders()
  .set('Access-Control-Allow-Headers', window.location.origin)
  .set('Access-Control-Allow-Origin', window.location.origin)
  .set('authorization', token)
    })
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)))
    .catch(
      err => {
        this._handleError(this, err);
      }
      );
};


public async getOrganizatonUsers(organization:string,token: string, active?:boolean): Promise<any> {
  const data: any = {organization:organization};
  if (active==true) {
    data.active=true;
  } else if (active==false) {
    data.active=false;
  }
  return this.http.post(ENV.API_GET_ORGANIZATION_USERS,data,
    { headers:
      new HttpHeaders()
  .set('Access-Control-Allow-Headers', window.location.origin)
  .set('Access-Control-Allow-Origin', window.location.origin)
  .set('authorization', token)
    })
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)))
    .catch(
      err => {
        this._handleError(this, err);
      }
      );
}
public async updatePerson(userId, person, preffered_organization,token: string): Promise<any> {
 console.log(person)
 let data ={ 
   person:person,
   organization:preffered_organization,
   userId: userId
 }

 console.log(data,"||||")
  return this.http.post(ENV.API_POST_CREATE_UPDATE_PERSON,data,
    { headers:
      new HttpHeaders()
  .set('Access-Control-Allow-Headers', window.location.origin)
  .set('Access-Control-Allow-Origin', window.location.origin)
  .set('authorization', token)
    })
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)))
    .catch(
      err => {
        this._handleError(this, err);
      }
      );
};

public async updateAllocPersons(userId, person, preffered_organization,token: string): Promise<any> {
  console.log(person)
  let data ={ 
    person:person,
    organization:preffered_organization,
    userId: userId
  }
 
  console.log(data,"||||")
   return this.http.post(ENV.API_UPDATE_ALLOC_PERSONS,data,
     { headers:
       new HttpHeaders()
   .set('Access-Control-Allow-Headers', window.location.origin)
   .set('Access-Control-Allow-Origin', window.location.origin)
   .set('authorization', token)
     })
     .toPromise()
     .then(response => JSON.parse(JSON.stringify(response)))
     .catch(
       err => {
         this._handleError(this, err);
       }
       );
 };



    public async getPersonDetails(person,token: string): Promise<any> {
      let data ={ 
        person:person
      }
      console.log(data,"||||")
      return this.http.post(ENV.API_GET_PERSON_DETAILS,data,
        { headers:
          new HttpHeaders()
      .set('Access-Control-Allow-Headers', window.location.origin)
      .set('Access-Control-Allow-Origin', window.location.origin)
      .set('authorization', token)
        })
        .toPromise()
        .then(response => JSON.parse(JSON.stringify(response)))
        .catch(
          err => {
            this._handleError(this, err);
          }
          );
    }

public async updateOrganization(preffered_org, org_body,token: string): Promise<any> {
  const data = {
    preffered_organization: preffered_org,
    org: org_body
  }
 
   return this.http.post(ENV.API_POST_UPDATE_ORGANIZATION,data,
     { headers:
       new HttpHeaders()
   .set('Access-Control-Allow-Headers', window.location.origin)
   .set('Access-Control-Allow-Origin', window.location.origin)
   .set('authorization', token)
     })
     .toPromise()
     .then(response => JSON.parse(JSON.stringify(response)))
     .catch(
       err => {
         this._handleError(this, err);
       }
       );
 };

 //updateorg +person moni type
 public async updateOrganizationAndPersonMonitoringPrice(preffered_org, org_body,token: string): Promise<any> {
  const data = {
    preffered_organization: preffered_org,
    org: org_body
  }
 
   return this.http.post(ENV.API_POST_UPDATE_ORGANIZATION_AND_PERSON_MONITORING_PRICE,data,
     { headers:
       new HttpHeaders()
   .set('Access-Control-Allow-Headers', window.location.origin)
   .set('Access-Control-Allow-Origin', window.location.origin)
   .set('authorization', token)
     })
     .toPromise()
     .then(response => JSON.parse(JSON.stringify(response)))
     .catch(
       err => {
         this._handleError(this, err);
       }
       );
 }

 public async getCollections(token:string):Promise<any>{
return this.http.get(ENV. API_GET_COLLECTIONS,
  {
    headers:
    new HttpHeaders()
.set('Access-Control-Allow-Headers', window.location.origin)
.set('Access-Control-Allow-Origin', window.location.origin)
.set('authorization', token)
  }
  )
   .toPromise()
   .then(response => JSON.parse( JSON.stringify(response)))
   .catch(
    err => {
      this._handleError(this, err);
    }
    );

 }
 
  public async getUserSuborganizations(preffered_organization, token: string, active?:boolean): Promise<any> {
    let data:any = {
      preffered_organization
    };
 
    return this.http.post(ENV.API_GET_SUBORGANIZATIONS,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  };

  public async getPersonByNin(token: string, person_cnp): Promise<any> {
    let data:any = {
      cnp: person_cnp
    };
   
    return this.http.post(ENV.API_GET_PERSON_BY_NIN,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  };

  public async getPersonByCode(token: string, person_code): Promise<any> {
    let data:any = {
      code: person_code
    };
   
    return this.http.post(ENV.API_GET_PERSON_BY_CODE,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  };

  public async getSummaryPersonBI(token: string, obj): Promise<any> {

    return this.http.post(ENV.API_GET_SUMMARY_PERSON_FROM_BI,obj,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  };


  public async getAllOrganizations(token:string):Promise<any> {
    return this.http.post(ENV.API_GET_ADMIN_ALL_ORGS,{},
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  };

  public async getOrganizations(token:string):Promise<any> {
    return this.http.post(ENV.API_GET_ADMIN_ORGS,{},
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  };

  public async getSuborgNameById(token: string, user?:any, active?:boolean): Promise<any> {
    let data:any = {
      user: user
    };
    if (active) {
      data.active=true;
    }
    return this.http.post(ENV.API_GET_SUBORGANIZATIONS_NAME_BY_ID,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  };

  public async updateSuborganization(data,preffered_organization,token: string): Promise<any> {
    const postData = {
      suborganization:data,
      preffered_org:preffered_organization
    }

    console.log(postData)

    return this.http.post(ENV.API_UPDATE_SUBORGANIZATION,postData,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  };

  public async updateAllocation(data,preffered_organization,token: string): Promise<any> {
    const postData = {
      suborganization:data,
      preffered_org:preffered_organization
    }

    console.log(postData)

    return this.http.post(ENV.API_UPDATE_ALLOCATION,postData,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  };

  public async updateRecords(data,preffered_organization,token: string): Promise<any> {
    const postData = {
      organization:data,
      preffered_org:preffered_organization
    }

    console.log(postData)

    return this.http.post(ENV.API_UPDATE_RECORDS,postData,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  };
 
public async swapAssignedDosimeters(oldDosimeterId, newDosimeterId, token) {
  let object={ old: oldDosimeterId, new:newDosimeterId};
  return this.http.post(ENV.API_ADMIN_SWAP_DOSIMETERS,object,
    { headers:
      new HttpHeaders()
  .set('Access-Control-Allow-Headers', window.location.origin)
  .set('Access-Control-Allow-Origin', window.location.origin)
  .set('authorization', token)
    })
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)))
    .catch(
      err => {
        this._handleError(this, err);
      }
      ); 
}

public async swapAllocationPersons(_allocationId, _oldPersonId, _newPersonId, token) {
  let object={ oldPersonId: _oldPersonId, newPersonId:_newPersonId, allocationId:_allocationId};
  return this.http.post(ENV.API_ADMIN_SWAP_PERSON_ID,object,
    { headers:
      new HttpHeaders()
  .set('Access-Control-Allow-Headers', window.location.origin)
  .set('Access-Control-Allow-Origin', window.location.origin)
  .set('authorization', token)
    })
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)))
    .catch(
      err => {
        this._handleError(this, err);
      }
      ); 
};

public async addExtraDoseRecord(token: string, object: any, _quantity:number){
  let data={ collection_name: "dose_record", quantity: _quantity, record: object}
  return this.http.post(ENV.API_ADMIN_ADD_EXTRA_DOSE_RECORDS,data,
    { headers:
      new HttpHeaders()
  .set('Access-Control-Allow-Headers', window.location.origin)
  .set('Access-Control-Allow-Origin', window.location.origin)
  .set('authorization', token)
    })
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)))
    .catch(
      err => {
        this._handleError(this, err);
      }
      );

}
public async updateDoseRecord(token: string, object?: any){
  let data={}
  return this.http.post(ENV.API_ADMIN_UPDATE_DOSE_RECORD,object,
    { headers:
      new HttpHeaders()
  .set('Access-Control-Allow-Headers', window.location.origin)
  .set('Access-Control-Allow-Origin', window.location.origin)
  .set('authorization', token)
    })
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)))
    .catch(
      err => {
        this._handleError(this, err);
      }
      )
};

public async updatePersonMonitoringType(token: string, object?: any){
  let data={}
  return this.http.post(ENV.API_ADMIN_UPDATE_PERSON_MONITORING_TYPE,object,
    { headers:
      new HttpHeaders()
  .set('Access-Control-Allow-Headers', window.location.origin)
  .set('Access-Control-Allow-Origin', window.location.origin)
  .set('authorization', token)
    })
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)))
    .catch(
      err => {
        this._handleError(this, err);
      }
      );

}

public async updateDosiData(token: string, object?: any){
  let data={}
  return this.http.post(ENV.API_ADMIN_UPDATE_DOSI_DATA,object,
    { headers:
      new HttpHeaders()
  .set('Access-Control-Allow-Headers', window.location.origin)
  .set('Access-Control-Allow-Origin', window.location.origin)
  .set('authorization', token)
    })
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)))
    .catch(
      err => {
        this._handleError(this, err);
      }
      );
}


  public async deleteSuborganization(suborg,preffered_org,token: string): Promise<any> {
    const deleteData = {
      suborganization: suborg,
      preffered_organization: preffered_org
    }

    console.log('DELETE DATA', deleteData)

    return this.http.post(ENV.API_DELETE_SUBORGANIZATION,deleteData,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }

  public async deleteAllocation(obj_id, token: string, object?: any ): Promise<any> {
    let data={ id: obj_id, allocation: object}
    return this.http.post(ENV.API_ADMIN_DELETE_ALLOCATION,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  };

  public async modifyBatches(payload, token): Promise<any> {

    return this.http.post(ENV.API_INVALIDATE_BACTH,payload,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }

  public async invalidateBatch(batch_id, token: string, object?:any): Promise<any> {
    let data={ id: batch_id, batch: object}
    return this.http.post(ENV.API_INVALIDATE_BACTH,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }

  public async deleteFreeDosimeter(token:string, dosi_id ):Promise<any> {
    let data={id: dosi_id}
    return this.http.post(ENV.API_ADMIN_DELETE_FREE_DOSIMETER,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );

  };



  // public async getDosimeters(token: string,  object:any): Promise<any> {

  //   return this.http.post(ENV.API_ADMIN_GET_DOSIMETERS,object,
  //     { headers:
  //       new HttpHeaders()
  //   .set('Access-Control-Allow-Headers', window.location.origin)
  //   .set('Access-Control-Allow-Origin', window.location.origin)
  //   .set('authorization', token)
  //     })
  //     .toPromise()
  //     .then(response => JSON.parse(JSON.stringify(response)))
  //     .catch(
  //       err => {
  //         this._handleError(this, err);
  //       }
  //       );
  // }



  // to be verified with Ion
  public async deleteOrganization(org,preffered_org,token: string): Promise<any> {
    const deleteData = {
      organization: org,
      preffered_organization: preffered_org
    }

    console.log('DELETE ORGANIZATION DATA', deleteData)

    return this.http.post(ENV.API_DELETE_ORGANIZATION,deleteData,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }
// ---------------------------------------------------------------------


  public async deletePerson(person,token: string): Promise<any> {
    const deleteData = {
      person: person
    }

    console.log('DELETE DATA', deleteData)

    return this.http.post(ENV.API_DELETE_PERSON,deleteData,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }

  public async getSystemUsers(token:string):Promise<any> {
    return this.http.get(ENV.API_ADMIN_GET_SYSTEM_USERS,
      {
        headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      }
      )
       .toPromise()
       .then(response => JSON.parse( JSON.stringify(response)))
       .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }
  public async getUserRoles(token:string): Promise<any>{
    return this.http.get(ENV.API_GET_USER_ROLES,
      {
        headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      }
    )
    
     .toPromise()
     .then(response => JSON.parse( JSON.stringify(response)))
     .catch(
      err => {
        this._handleError(this, err);
      }
      );
  }

  public async getUserNonSystemRoles(){
    return this.http.get(ENV.API_GET_USER_NON_SYSTEM_ROLES,
      )
       .toPromise()
       .then(response => JSON.parse( JSON.stringify(response)))
       .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }

  public async createUser(data: any, token:string): Promise<any> {
    console.log(data);
     return this.http.post(ENV.API_POST_CREATE_USER , data,
       {
        headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
       .toPromise()
       .then(response => JSON.parse( JSON.stringify(response)))
       .catch(
        err => {
          this._handleError(this, err);
        }
        );
   }

   public async deleteUser(userId: any, token:string): Promise<any> {
     const user ={
       id: userId
     }
     return this.http.post(ENV.API_POST_DELETE_USER , user,
       {
        headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
       .toPromise()
       .then(response => JSON.parse( JSON.stringify(response)))
       .catch(
        err => {
          this._handleError(this, err);
        }
        );
   };

   public async deleteNotification(notification: any, token:string): Promise<any> {
    const notific ={
      notif: notification
    }
    return this.http.post(ENV.API_POST_DELETE_NOTIFICATION , notific,
      {
       headers:
       new HttpHeaders()
   .set('Access-Control-Allow-Headers', window.location.origin)
   .set('Access-Control-Allow-Origin', window.location.origin)
   .set('authorization', token)
     })
      .toPromise()
      .then(response => JSON.parse( JSON.stringify(response)))
      .catch(
       err => {
         this._handleError(this, err);
       }
       );
  };



   public async getParentOrganizations(token: string, query?:any): Promise<any> {
    let data = {};
    if (query) { data=query; }
    return this.http.post(ENV.API_GET_PARENT_ORGANIZATIONS,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  };

  // public async getParentOrganizAdmin(token: string, query?:any, active?:boolean, count_wearers?:boolean): Promise<any> {
  //   let data:any = {};
  //   if (query) { data=query; }
  //   if (active!=undefined) {
  //     data.active=active;
  //   }
  //   if (count_wearers) {
  //     data.countwearers=true;
  //   }
  //   console.log("getParentOrganizationsAdmin data is ",active, count_wearers, data)
  //   return this.http.post(ENV.API_ADMIN_GET_PARENT_ORGANIZATIONS,data,
  //     { headers:
  //       new HttpHeaders()
  //   .set('Access-Control-Allow-Headers', window.location.origin)
  //   .set('Access-Control-Allow-Origin', window.location.origin)
  //   .set('authorization', token)
  //     })
  //     .toPromise()
  //     .then((response) => {
  //       let organizations: OrganizationModel[] = JSON.parse(JSON.stringify(response));
  
  //       organizations.forEach((org) => {
  //         let statusChange = { date: new Date(), active: org.active };
  //         org.statusChangeHistory.push(statusChange);
  //       });
  
  //       return organizations;
  //     })
  //     .catch(
  //       err => {
  //         this._handleError(this, err);
  //       }
  //       );
  // }

  public async getParentOrganizationsAdmin(token: string, query?:any, active?:boolean, count_wearers?:boolean): Promise<any> {
    let data:any = {};
    if (query) { data=query; }
    if (active!=undefined) {
      data.active=active;
    }
    if (count_wearers) {
      data.countwearers=true;
    }
    
    console.log("getParentOrganizationsAdmin data is ",active, count_wearers, data)
    return this.http.post(ENV.API_ADMIN_GET_PARENT_ORGANIZATIONS,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }

  public async getOrganizationPersonsAdmin(organization: any, token: string, active?:boolean, 
    code?:string, firstName?: string, lastName?:string, dob?:Date,
    extra?:boolean, irregular?:boolean, area?:boolean, cnp?:string): Promise<any> {
    // const data = {
    //   organization: organization,
    //   includeChildren: true
    // }

    let data:any = {organization:organization,
      includeChildren: true};
    if (active==true) {
      data.active=true;
    } else if (active==false) {
      data.active=false;
    }
    if(extra!=undefined){
      data.extra=extra
    } 
  
    if(irregular!=undefined){
      data.irregular=irregular
    }  
    if(area==true){
      data.area=true
    }
    if (code!=undefined && Number(code)!=0) {
      data.code=code;
    }
    if (firstName!=undefined && lastName!=undefined) {
      data.firstName=firstName;
      data.lastName=lastName;
      if (dob!=undefined) {
        data.dob=dob;
      }
      if (cnp!=undefined) {
        data.cnp=cnp;
      }
      if (data.cnp=="") data.cnp=null;
    }
 
   
    return this.http.post(ENV.API_ADMIN_GET_PERSONS,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  };


  public async getPersonsFromOtherCompanies(token: string, 
    code?: string, firstName?: string, lastName?: string, dob?: Date,
    extra?: boolean, irregular?: boolean, area?: boolean, cnp?: string): Promise<any> {
    const data: any = {
      includeChildren: true
    };

    // if (active === true) {
    //   data.active = true;
    // } else if (active === false) {
    //   data.active = false;
    // }

    if (extra !== undefined) {
      data.extra = extra;
    }

    if (irregular !== undefined) {
      data.irregular = irregular;
    }

    if (area === true) {
      data.area = true;
    }

    if (code !== undefined && Number(code) !== 0) {
      data.code = code;
    }

    if (firstName !== undefined && lastName !== undefined) {
      data.firstName = firstName;
      data.lastName = lastName;
      if (dob !== undefined) {
        data.dob = dob;
      }
      if (cnp !== undefined) {
        data.cnp = cnp;
      }
      if (data.cnp == "") {
        data.cnp = null;
      }


    }

    return this.http.post(ENV.API_ADMIN_GET_PERSONS_FROM_ALL_ORGANIZATIONS,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
 
}

  public async getOrganizationUsersAdmin(organization: string, token: string, active?:boolean): Promise<any> {
    const data:any = {
      organization: organization,
      includeChildren: true};
    if (active==true) {
      data.active=true;
    } else if (active==false) {
      data.active=false;
    }
    return this.http.post(ENV.API_ADMIN_GET_USERS,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  };

  public async getUsersSuborg(organization: string, token: string, active?:boolean): Promise<any> {
    const data:any = {
      organization: organization,
      includeChildren: true};
    if (active==true) {
      data.active=true;
    } else if (active==false) {
      data.active=false;
    }
    return this.http.post(ENV.API_ADMIN_GET_SUBORGS_USERS,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }

  public async getAllocableWearers(date:Date, organization: string, service: string, dosimeterMake: string, dosimeterType: string, token: string,
    frequency: string, minCode:number, maxCode:number,
    client_minCode:number, client_maxCode:number, address:any
    ) {
    let data:any= { 'date':date, 'organization': organization,  'service': service, 'frequency': frequency, 'min_organization_code':minCode, 'max_organization_code': maxCode, 'dosimeter_make':dosimeterMake, 'dosimeter_type':dosimeterType};
    console.log("get allocable wearers ", data);
    if (client_minCode) { data.client_minCode=client_minCode; }
    if (client_maxCode) { data.client_maxCode=client_maxCode; }
    if (address.country) { data.country=address.country; }
    if (address.county) { data.county=address.county; }
    return this.http.post(ENV.API_GET_ALLOCABLE_WEARERS,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  };

  public async countDocuments(query, token: string) {
    const data=query;
    console.log("get allocable dosimeters ", data);
    return this.http.post(ENV.API_COUNT_STUFFS,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );

  }


  public async getUnwidedDosimetryTypes(dosimeter_make: string, dosimeter_type: string, token: string) {
    let data= {'make': dosimeter_make, 'type': dosimeter_type}

    return this.http.post(ENV.API_ADMIN_GET_UNWIND_DOSIMETERS,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
    
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }

  public async postGeneric(token: string, object:any, list:any): Promise<any> {
    let data;
    if(object!==null){
      data = {
        object: object
      };
    }
    else if (list!==null) {
      data = {
        objects: {
          collection_name: "dosimeters",
          values: list
        }
      };
    }
    return this.http.post(ENV.API_ADMIN_POST_GENERIC,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }
  public async getGenericPerson(token: string, object:any, selected?:any): Promise<any> {
    let data;
    if(selected){
      data = {
        object: object,
        selected: selected
      };
    }
    else {
      data = {
        object: object
      };
    }
   
    return this.http.post(ENV.API_ADMIN_GET_GENERIC_PERSON,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }
  public async getGeneric(token: string, object:any, selected?:any): Promise<any> {
    let data;
    if(selected){
      data = {
        object: object,
        selected: selected
      };
    }
    else {
      data = {
        object: object
      };
    }
   
    return this.http.post(ENV.API_ADMIN_GET_GENERIC,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }

  public async updateGeneric(token: string, object?:any): Promise<any> {
    let data;
    if(object){
      data = {
        object: object
      };
    }
    else {
      data = {};
    } 
   
    return this.http.post(ENV.API_ADMIN_UPDATE_GENERIC,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }


  public async updateReadDoses(token:string, arr:any, q:any, col:any):Promise<any>
  {
    let data={ objects: arr, query: q, collection_name:col}
    return this.http.post(ENV.API_ADMIN_UPDATE_ALLOCATION_QUERY,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }
  public async updateManyGenericQuery(token: string, arr?:any, q?:any, col?:any): Promise<any> {
    let data={ objects: arr, query: q, collection_name:col}
    console.log("updating by query ",data)
    return this.http.post(ENV.API_ADMIN_UPDATE_GENERIC_MANY_QUERY,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }

  public async getDosimeterObjects(token: string, arr?:any, q?:any, col?:any): Promise<any> {
    let data={ dosimeters: arr, query: q, collection_name:col}
    console.log("updating dosimeters ",data)
    return this.http.post(ENV.API_ADMIN_UPDATE_DOSIMETER_STATES,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }

  
  public async updateMultipleCollections(token: string, arr?:any, q?:any, col?:any): Promise<any> {
    let data={ objects: arr, query: q, collection_names:col};
    console.log("updating by query ",data)
    return this.http.post(ENV.API_ADMIN_UPDATE_GENERIC_MULTIPLE_COLLECTIONS,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }

  public async searchDosimeters(token: string, arr?:any, q?:any, col?:any): Promise<any> {
    let data={ objects: arr, query: q, collection_names:col};
    console.log("updating by query ",data)
    return this.http.post(ENV.API_ADMIN_SEARCH_DOSIMETERS,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }

  public async updateValidDose(token: string, arr:any, q:any, col:any): Promise<any> {
    let data={objects: arr, query: q, collection_name:col};
    console.log("updating by query ",data)
    return this.http.post(ENV.API_ADMIN_UPDATE_VALID_DOSE,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }

  public async updateReturnedDosimeters(token: string, arr?:any, q?:any, col?:any): Promise<any> {
    let data={ objects: arr, query: q, collection_names:col}
    console.log("updating returned dosi ",data)
    return this.http.post(ENV.API_ADMIN_UPDATE_GENERIC_RETURNED_DOSIMETERS,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }
  public async updateAnnealedDosimeters(token: string, arr?:any, q?:any, col?:any): Promise<any> {
    let data={ objects: arr, query: q, collection_names:col}
    console.log("updating annealed dosi ",data)
    return this.http.post(ENV.API_ADMIN_UPDATE_GENERIC_ANNEALED_DOSIMETERS,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }

  public async updateManyGeneric(token: string, arr?:any): Promise<any> {
    let data={ objects: arr}
    return this.http.post(ENV.API_ADMIN_UPDATE_GENERIC_MANY,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }

  
  public async updateBulkAdd(token: string, arr?:any): Promise<any> {
    let data={ objects: arr}
    return this.http.post(ENV.API_ADMIN_UPDATE_BULK_ADD,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }

// dani
public async updateDoses(token: string, arr?:any): Promise<any> {
  let data={ objects: arr}
  return this.http.post(ENV.API_ADMIN_UPDATE_ALLOCATION_QUERY,data,
    { headers:
      new HttpHeaders()
  .set('Access-Control-Allow-Headers', window.location.origin)
  .set('Access-Control-Allow-Origin', window.location.origin)
  .set('authorization', token)
    })
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)))
    .catch(
      err => {
        this._handleError(this, err);
      }
      );
}




  public async assignDosimeter (token: string, object?: any): Promise<any> {

    return this.http.post(ENV.API_ADMIN_ASIGN_DOSIMETER_TO_WEARER,object,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }

  public async deleteGeneric(token: string, object?:any): Promise<any> {
    let data;
    if(object){
      data = {
        object: object
      };
    }
    else {
      data = {};
    }
   
    return this.http.post(ENV.API_ADMIN_DELETE_GENERIC,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }

  public async uploadFile(token: string, object:any): Promise<any> {

    return this.http.post(ENV.API_ADMIN_UPLOAD_FILE,object,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }


  public async validateAssigment(token: string,object:any): Promise<any> {

    return this.http.post(ENV.API_ADMIN_VALIDATE_ASSIGMENT,object,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)

      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }

  public async findAssignedDosimeter(token: string, object:any): Promise<any>{

    return this.http.post(ENV.API_ADMIN_FIND_ASSIGNED_DOSIMETER,object,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)

      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );

  }
 
  

  public async getBatches(token: string,  object:any): Promise<any> {

    return this.http.post(ENV.API_ADMIN_GET_BATCHES,object,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }

  public async getDosesBatches(token: string,  object:any): Promise<any> {

    return this.http.post(ENV.API_ADMIN_GET_DOSES_BATCHES,object,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }

  public async getBatcheDetails(token: string, object:any): Promise<any> {

    return this.http.post(ENV.API_ADMIN_GET_BATCH_DETAILS,object,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }
  public async getBatchesReportDetails(token: string, object:any): Promise<any> {

    return this.http.post(ENV.API_ADMIN_GET_BATCH_REPORT_DETAILS,object,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }
  public async getPrinterList():Promise<any> {
    return this.http.get(ENV.API_ADMIN_GET_PRINTERS,{})
    .toPromise()
    .then(result=>JSON.parse(JSON.stringify(result)))
    .catch(
      err=> { this._handleError(this, err)}
    )
  }

  public async getPrintLabels(_flt?:any):Promise<any> {
    var filter={};
    if (_flt) {  filter=_flt; }
    console.log("getPrintLabels", filter)
    return this.http.post(ENV.API_ADMIN_GET_PRINT_LABELS,filter)
    .toPromise()
    .then(result=>JSON.parse(JSON.stringify(result)))
    .catch(
      err=> { this._handleError(this, err)}
    )
  }

  public async checkUploadedList(token: string, object:any): Promise<any> {
    let data;
    if(object){
      data = {
        object: object
      };
    }
    else {
      data = {};
    }
    return this.http.post(ENV.API_ADMIN_CHECK_UPLOADED_LIST,data,
      { headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', window.location.origin)
    .set('authorization', token)
      })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)))
      .catch(
        err => {
          this._handleError(this, err);
        }
        );
  }

    public async printLabel(printer, value, token) {
      let data={ printer: printer, label:value};

      return this.http.post(ENV.API_ADMIN_POST_PRINT_LABEL,data,
        { headers:
          new HttpHeaders()
      .set('Access-Control-Allow-Headers', window.location.origin)
      .set('Access-Control-Allow-Origin', window.location.origin)
      .set('authorization', token)
        })
        .toPromise()
        .then(response => JSON.parse(JSON.stringify(response)))
        .catch(
          err => {
            this._handleError(this, err);
          }
          );



    }
    public async deleteLabel(value, token) {
      let data={ label:value};

      return this.http.post(ENV.API_ADMIN_POST_DELETE_LABEL,data,
        { headers:
          new HttpHeaders()
      .set('Access-Control-Allow-Headers', window.location.origin)
      .set('Access-Control-Allow-Origin', window.location.origin)
      .set('authorization', token)
        })
        .toPromise()
        .then(response => JSON.parse(JSON.stringify(response)))
        .catch(
          err => {
            this._handleError(this, err);
          }
          );

    }
    public async deleteData(_query, token) {
      let data=_query;

      return this.http.post(ENV.API_ADMIN_DELETE_DATA,data,
        { headers:
          new HttpHeaders()
      .set('Access-Control-Allow-Headers', window.location.origin)
      .set('Access-Control-Allow-Origin', window.location.origin)
      .set('authorization', token)
        })
        .toPromise()
        .then(response => JSON.parse(JSON.stringify(response)))
        .catch(
          err => {
            this._handleError(this, err);
          }
          );

    }

    public async postGenericQuery(_path, _query, token) {
      let data=_query;

      return this.http.post(ENV.API_URL+_path,data,
        { headers:
          new HttpHeaders()
      .set('Access-Control-Allow-Headers', window.location.origin)
      .set('Access-Control-Allow-Origin', window.location.origin)
      .set('authorization', token)
        })
        .toPromise()
        .then(response => JSON.parse(JSON.stringify(response)))
        .catch(
          err => {
            this._handleError(this, err);
          }
          );

    }

    public async lookupUsers(token, q):Promise<any> {
      return this.http.post(ENV.API_ADMIN_POST_LOOKUPUSER,{ criteria: q},
        { headers:
          new HttpHeaders()
      .set('Access-Control-Allow-Headers', window.location.origin)
      .set('Access-Control-Allow-Origin', window.location.origin)
      .set('authorization', token)
        })
        .toPromise()
        .then(response => JSON.parse(JSON.stringify(response)))
        .catch(
          err => {
            this._handleError(this, err);
          }
          );
    }
    public async registerDoseReport(token, q):Promise<any> {
      return this.http.post(ENV.API_ALLOCATION_REGISTER_DOSE_REPORT,q,
        { headers:
          new HttpHeaders()
      .set('Access-Control-Allow-Headers', window.location.origin)
      .set('Access-Control-Allow-Origin', window.location.origin)
      .set('authorization', token)
        })
        .toPromise()
        .then(response => JSON.parse(JSON.stringify(response)))
        .catch(
          err => {
            this._handleError(this, err);
          }
          );
    }
    public async getMainOrganizationAndBelow(token, org:any, orgIds:any[]):Promise<any> {
      let data={ org: org, org_list: orgIds}
      return this.http.post(ENV.API_POST_PARENT_AND_SUBORGANIZATIONS, data,
        { headers:
          new HttpHeaders()
      .set('Access-Control-Allow-Headers', window.location.origin)
      .set('Access-Control-Allow-Origin', window.location.origin)
      .set('authorization', token)
        })
        .toPromise()
        .then(response => JSON.parse(JSON.stringify(response)))
        .catch(
          err => {
            this._handleError(this, err);
          }
          );
    };
    public async sequencialWearerCode(token, code:number):Promise<any> {
      
      return this.http.post(ENV.API_ADMIN_SEQUENCIAL_WEARER_CODE, code,
        { headers:
          new HttpHeaders()
      .set('Access-Control-Allow-Headers', window.location.origin)
      .set('Access-Control-Allow-Origin', window.location.origin)
      .set('authorization', token)
        })
        .toPromise()
        .then(response => JSON.parse(JSON.stringify(response)))
        .catch(
          err => {
            this._handleError(this, err);
          }
          );
    };

    public async generateReportNotifications(token, notification:any):Promise<any> {
      
      return this.http.post(ENV.API_ADMIN_POST_REPORT_NOTIFICATION, notification,
        { headers:
          new HttpHeaders()
      .set('Access-Control-Allow-Headers', window.location.origin)
      .set('Access-Control-Allow-Origin', window.location.origin)
      .set('authorization', token)
        })
        .toPromise()
        .then(response => JSON.parse(JSON.stringify(response)))
        .catch(
          err => {
            this._handleError(this, err);
          }
          );
    };

    //get notifications for shipped dozi
    public async generateShippedDosimetersNotifications(token, notification:any):Promise<any> {
      return this.http.post(ENV.API_ADMIN_POST_SHIPPED_DOSIMETERS_NOTIFICATION, notification,
        { headers:
          new HttpHeaders()
      .set('Access-Control-Allow-Headers', window.location.origin)
      .set('Access-Control-Allow-Origin', window.location.origin)
      .set('authorization', token)
        })
        .toPromise()
        .then(response => JSON.parse(JSON.stringify(response)))
        .catch(
          err => {
            this._handleError(this, err);
          }
          );
    };

  
    public async generatePDF(token, noderef:any):Promise<any> {
      
      return this.http.get(ENV.API_ALFRESCO_GENERATE_PDF + noderef,
        { headers:
          new HttpHeaders()
      .set('Access-Control-Allow-Headers', window.location.origin)
      .set('Access-Control-Allow-Origin', window.location.origin)
      .set('authorization', token)
        })
        .toPromise()
        .then(response => JSON.parse(JSON.stringify(response)))
        .catch(
          err => {
            this._handleError(this, err);
          }
          );
    };
    public async browseSiteFolders(token, path):Promise<any> {
      
      return this.http.get(ALF_ENV.API_GET_BROWSE_SITE_FOLDER + path,
        { headers:
          new HttpHeaders()
          .set('Access-Control-Allow-Headers', window.location.origin)
          .set('Access-Control-Allow-Origin', window.location.origin)
          .set('authorization', token)
        })
        .toPromise()
        .then(response => JSON.parse(JSON.stringify(response)))
        .catch(
          err => {
            this._handleError(this, err);
          }
          );
    };

    public async openPDFFileInWindow(_url:any, token: any):Promise<any> {
      console.log("opening pdf file in window???? ", _url)
      let _resp = await this.http.get(_url,
        { 
          responseType: 'blob',
          headers:
          new HttpHeaders()
      .set('Access-Control-Allow-Headers', window.location.origin)
      .set('Access-Control-Allow-Origin', window.location.origin)
      .set('authorization', token)
        })
        .toPromise()

          .then(function(response) {
            var fileURL = URL.createObjectURL(response);
            window.open(fileURL);
          
        })
        .catch(
          err => {
            this._handleError(this, err);
          }
          );

    };

    public async browseReportFolders(token, _data:any):Promise<any> {
      
      return this.http.post(ENV.API_ALFRESCO_BROWSE_REPORT_FOLDER, { data: _data },
        { headers:
          new HttpHeaders()
      .set('Access-Control-Allow-Headers', window.location.origin)
      .set('Access-Control-Allow-Origin', window.location.origin)
      .set('authorization', token)
        })
        .toPromise()
        .then(response => JSON.parse(JSON.stringify(response)))
        .catch(
          err => {
            this._handleError(this, err);
          }
          );
    };
    public async quickGenerator(token, _data:any):Promise<any> {
      
      return this.http.post(ENV.API_ALFRESCO_QUICK_GENERATOR, { payload: _data },
        { headers:
          new HttpHeaders()
      .set('Access-Control-Allow-Headers', window.location.origin)
      .set('Access-Control-Allow-Origin', window.location.origin)
      .set('authorization', token)
        })
        .toPromise()
        .then(response => JSON.parse(JSON.stringify(response)))
        .catch(
          err => {
            this._handleError(this, err);
          }
          );
    };


    public async getAuditLogs(personId: any, token:string): Promise<any> {
      const person ={
        id: personId
      }
      return this.http.post(ENV.API_AUDIT_LOGS , person,
        {
         headers:
         new HttpHeaders()
     .set('Access-Control-Allow-Headers', window.location.origin)
     .set('Access-Control-Allow-Origin', window.location.origin)
     .set('authorization', token)
       })
        .toPromise()
        .then(response => JSON.parse( JSON.stringify(response)))
        .catch(
         err => {
           this._handleError(this, err);
         }
         );
};

//get dose records
public async getDoseRecords(date:Date, code: number, dosimeterId: string, monitoring_type: string, token: string) {
  let data = {
    date: date ? date.toISOString() : null, 
    code: code || "",
    dosimeterId: dosimeterId || "",
    monitoring_type: monitoring_type || ""
  };

  return this.http.post(ENV.API_GET_DOSE_RECORDS,data,
    { headers:
      new HttpHeaders()
  .set('Access-Control-Allow-Headers', window.location.origin)
  .set('Access-Control-Allow-Origin', window.location.origin)
  .set('authorization', token)
    })
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)))
    .catch(
      err => {
        this._handleError(this, err);
      }
      );
};

//search wearer
public async searchWearers(cnp:string, code: number, firstName: string, lastName: string, token: string) {
  let data = {
    cnp: cnp ,
    code: code || "",
    firstName: firstName || "",
    lastName: lastName || ""
  };

  return this.http.post(ENV.API_SEARCH_WEARERS,data,
    { headers:
      new HttpHeaders()
  .set('Access-Control-Allow-Headers', window.location.origin)
  .set('Access-Control-Allow-Origin', window.location.origin)
  .set('authorization', token)
    })
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)))
    .catch(
      err => {
        this._handleError(this, err);
      }
      );
};

//verify password
public async verifyPassword(username: any, password, token:string): Promise<any> {
  const userName ={
    username: username,
    password: password
  }
  return this.http.post(ENV.API_VERIFY_PASSWORD , userName,
    {
     headers:
     new HttpHeaders()
 .set('Access-Control-Allow-Headers', window.location.origin)
 .set('Access-Control-Allow-Origin', window.location.origin)
 .set('authorization', token)
   })
    .toPromise()
    .then(response => JSON.parse( JSON.stringify(response)))
    .catch(
     err => {
       this._handleError(this, err);
     }
     );
};

}




